import { FC, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { DCInfo, Nav, Studies } from '../../components';
import { saveAuthToken } from '../../utils/tokenHelpers';
import useStore from '../../hooks/useStore';
import { observer } from 'mobx-react-lite';

const SharedStudies: FC = () => {
  const location = useLocation();

  const { patientStore } = useStore();

  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    (async () => {
      if (!token) {
        return;
      }
      saveAuthToken(token, '');
      await patientStore.getStudies();
    })();
  }, [token]);

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <DCInfo />
      <Nav />

      <Studies sharing={false} />
    </>
  );
};

export default observer(SharedStudies);
