import { flow, types } from 'mobx-state-tree';
import { LoadingStatus } from './types';
import { FilesApi } from '../api/files';

const File = types.model('File', {
  url: types.string,
});

const FileStore = types
  .model('FileStore', {
    file: types.maybeNull(File),
    getFileLoadingStatus: types.optional(
      types.enumeration<LoadingStatus>(Object.values(LoadingStatus)),
      LoadingStatus.NEVER
    ),
    getFileError: types.optional(types.string, ''),
    unauthorizedError: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const setGetFileLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.getFileLoadingStatus = loadingStatus;
    };

    const reset = () => {
      self.file = null;
      self.unauthorizedError = true;
      self.getFileError = '';
      self.getFileLoadingStatus = LoadingStatus.NEVER;
    };

    const getFileById = flow(function* (studyUUID: string, fileUUID: string) {
      try {
        setGetFileLoadingStatus(LoadingStatus.LOADING);
        self.file = yield FilesApi.getFileById(studyUUID, fileUUID);
        setGetFileLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e: any) {
        self.getFileError = e.message;
        setGetFileLoadingStatus(LoadingStatus.ERROR);
      }
    });

    return {
      setGetFileLoadingStatus,
      getFileById,
      reset,
    };
  });

export default FileStore;
