import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import './App.scss';

import { DownloadFilePage, LoginPage, Patient, SharedStudies } from './pages';
import useStore from './hooks/useStore';
import { clearAuthToken, isTokenExpired } from './utils/tokenHelpers';

const App = () => {
  const history = useHistory();
  const location = useLocation();

  const { patientStore, fileStore } = useStore();

  useEffect(() => {
    const querySearchParams = new URLSearchParams(location.search);
    const login = querySearchParams.get('login');
    const password = querySearchParams.get('password');

    if (login && password) {
      return;
    }

    (async () => {
      if (!location.pathname.includes('/sharedStudies') && isTokenExpired()) {
        history.push('/login');
        clearAuthToken();
      }
    })();
  }, [history, location.search]);

  useEffect(() => {
    if (patientStore.unauthorizedError || fileStore.unauthorizedError) {
      history.push('/login');
    }
  }, [history, patientStore.unauthorizedError, fileStore.unauthorizedError]);

  return (
    <Switch>
      <Route exact path={'/login'} component={LoginPage} />
      <Route
        exact
        path={['/sharedStudies', '/sharedStudies/:studyUUID/file/:fileUUID']}
        component={SharedStudies}
      />
      <Route
        exact
        path={['/', '/studies/:studyUUID/file/:fileUUID']}
        component={Patient}
      />
      <Route
        exact
        path={'/studies/:studyUUID/files/:fileUUID'}
        component={DownloadFilePage}
      />
      <Route render={() => <div>Сторінку не знайдено</div>} />
    </Switch>
  );
};

export default observer(App);
