import axios from 'axios';
import { baseurl } from './baseurl';
import makeRequest from './makeRequest';

export interface IDCInfo {
  welcomeText: string;
  phones: string;
}

const origin =
  window.location.hostname === 'localhost'
    ? 'https://patient1.intech-ua.com'
    : window.location.origin;

export const DCInfoApi = {
  getInfo: async () => {
    const { data } = await makeRequest<IDCInfo>({
      url: baseurl + '/dcinfo',
      params: {
        origin,
      },
    });
    return data;
  },
  getLogo: async () => {
    const { data } = await axios.get<Blob>(baseurl + '/dclogo', {
      params: {
        origin,
      },
      responseType: 'blob',
    });

    let url = '';
    if (data.type === 'text/xml') {
      const blob = new Blob([data], { type: 'image/svg+xml' });
      url = URL.createObjectURL(blob);
    } else if (data.type !== 'application/json') {
      url = URL.createObjectURL(data);
    }

    return { url };
  },
};
