import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Result } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import styles from './StudyFile.module.scss';

import Loader from '../Loader';
import useStore from '../../hooks/useStore';
import { FilesApi } from '../../api/files';
import { getAuthToken } from '../../utils/tokenHelpers';

const StudyFile: FC = () => {
  const params = useParams<{ studyUUID: string; fileUUID: string }>();
  const history = useHistory();
  const location = useLocation();
  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { cashFiles } = useStore();

  useEffect(() => {
    (async () => {
      try {
        if (!params.studyUUID || !params.fileUUID) {
          return;
        }
        setError('');
        setLoading(true);
        document.body.style.overflow = 'hidden';
        let file;
        file = cashFiles.getFile(params.fileUUID);
        if (file) {
          setFileUrl(file.blobUrl);
          return;
        }
        const { url, filename } = await FilesApi.getFileById(
          params.studyUUID,
          params.fileUUID
        );
        cashFiles.addFile({
          serverUrl: params.fileUUID,
          blobUrl: url,
          name: filename,
        });
        setFileUrl(url);
      } catch (e: any) {
        setError(
          e.message || 'Файл недоступний, зверніться до діагностичного центру'
        );
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      document.body.style.overflow = '';
    };
  }, [params]);

  const handleClose = () => {
    if (location.pathname.includes('/sharedStudies')) {
      history.push(`/sharedStudies?token=${getAuthToken().token}`);
      return;
    }
    history.push('/');
  };

  return (
    <div className={styles.studyFile}>
      <button className={styles.close} onClick={handleClose}>
        <CloseOutlined rev />
      </button>
      {!loading ? (
        error ? (
          <div className={styles.errorBlock}>
            <Result status="error" subTitle={error} />
          </div>
        ) : (
          <iframe className={styles.pdf} src={fileUrl} title="pdf" />
        )
      ) : (
        <div className={styles.studyFile}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default observer(StudyFile);
