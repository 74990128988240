import { cast, flow, Instance, types } from 'mobx-state-tree';
import { LoadingStatus } from './types';
import { PatientApi } from '../api/patient';
import { FilesApi } from '../api/files';
import { pause } from '../utils/pause';
import { ShareParams, SharesApi } from '../api/shares';

export type IsoStatus = 'none' | 'creating' | 'ready' | 'error' | 'unknown';
export type IsoStorage = 'mis' | 'local';

const StudyDownload = types.model('StudyDownload', {
  type: types.string,
  fileUUID: types.string,
  storage: types.maybeNull(types.enumeration<IsoStorage>(['mis', 'local'])),
  isoStatus: types.maybe(
    types.enumeration<IsoStatus>([
      'none',
      'creating',
      'ready',
      'error',
      'unknown',
    ])
  ),
  message: types.maybe(types.string),
});

export interface IStudyDownload extends Instance<typeof StudyDownload> {}

const PatientStudy = types.model('PatientStudy', {
  studyUUID: types.string,
  date: types.string,
  services: types.string,
  downloads: types.array(StudyDownload),
});

export interface IPatientStudy extends Instance<typeof PatientStudy> {}

const Patient = types.model('Patient', {
  patientID: types.string,
  patientUUID: types.string,
  name: types.string,
  birthDate: types.string,
  phone: types.string,
  visits: types.array(PatientStudy),
});

const Share = types.model('Share', {
  objectType: types.enumeration<'study' | 'patient'>(['study', 'patient']),
  objectUUID: types.string,
  token: types.string,
  expires: types.maybeNull(types.string),
});

export interface IPatient extends Instance<typeof Patient> {}

export interface IShare extends Instance<typeof Share> {}

const PatientStore = types
  .model('PatientStore', {
    patients: types.optional(types.array(Patient), []),
    shares: types.optional(types.array(Share), []),
    getPatientsLoadingStatus: types.optional(
      types.enumeration<LoadingStatus>(Object.values(LoadingStatus)),
      LoadingStatus.NEVER
    ),
    getPatientError: types.optional(types.string, ''),
    shareLoadingStatus: types.optional(
      types.enumeration<LoadingStatus>(Object.values(LoadingStatus)),
      LoadingStatus.NEVER
    ),
    unauthorizedError: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const setGetPatientLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.getPatientsLoadingStatus = loadingStatus;
    };

    const setGetPatientError = (errorMessage: string) => {
      self.getPatientError = errorMessage;
    };

    const setShareLoading = (loadingStatus: LoadingStatus) => {
      self.shareLoadingStatus = loadingStatus;
    };

    const getPatient = flow(function* () {
      try {
        setGetPatientLoadingStatus(LoadingStatus.LOADING);
        self.patients = yield PatientApi.getPatients();
        setGetPatientLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e: any) {
        self.getPatientError = e.message;
        setGetPatientLoadingStatus(LoadingStatus.ERROR);
      }
    });

    const getStudies = flow(function* () {
      try {
        setGetPatientLoadingStatus(LoadingStatus.LOADING);
        const studies = yield SharesApi.getSharedStudies();
        self.patients = Array.isArray(studies)
          ? cast(studies)
          : cast([studies]);
        setGetPatientLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e: any) {
        self.getPatientError = e.message;
        setGetPatientLoadingStatus(LoadingStatus.ERROR);
      }
    });

    const getShares = flow(function* (patientUUID: string) {
      try {
        self.shares = yield SharesApi.shares(patientUUID);
      } catch (e: any) {
        self.getPatientError = e.message;
      }
    });

    const share = flow(function* (params: ShareParams) {
      try {
        setShareLoading(LoadingStatus.LOADING);
        const shared = yield SharesApi.share(params);

        self.shares = cast(
          self.shares.filter((s) => s.objectUUID !== params.objectUUID)
        );

        if (params.operation === 'create') {
          self.shares.push(shared);
        }

        setShareLoading(LoadingStatus.SUCCESS);
      } catch (e: any) {
        setShareLoading(LoadingStatus.ERROR);
      }
    });

    const getStudyDownload = (studyUUID: string | number) => {
      for (const patient of self.patients) {
        for (const visit of patient.visits) {
          if (visit.studyUUID === studyUUID) {
            for (const download of visit.downloads) {
              if (download.type === 'Образ диску') {
                return download;
              }
            }
          }
        }
      }
    };

    // @ts-ignore
    const checkFileStatus = flow(function* (
      studyUUID: string | number,
      fileUUID: string
    ) {
      try {
        const { data } = yield FilesApi.checkIsoFileStatus(studyUUID, fileUUID);
        const studyDownload = getStudyDownload(studyUUID);
        if (studyDownload) {
          studyDownload.isoStatus = data.iso_status;
          studyDownload.message = data.message;

          if (studyDownload.isoStatus === 'ready') {
            studyDownload.fileUUID = data.fileUUID;
            studyDownload.storage = data.storage;
          }
        }
      } catch (e: any) {
        console.log(e);
      }
    });

    const buildStudyImage = flow(function* (studyUUID: string | number) {
      try {
        const { data } = yield FilesApi.buildIsoFile(studyUUID);
        const studyDownload = getStudyDownload(studyUUID);
        if (studyDownload) {
          studyDownload.isoStatus = data.iso_status;
          studyDownload.message = data.message;

          while (studyDownload.isoStatus === 'creating') {
            yield pause(60000);
            // yield checkFileStatus(studyUUID);
          }
        }
      } catch (e: any) {}
    });

    return {
      getPatient,
      getStudies,
      getShares,
      setShareLoading,
      setGetPatientLoadingStatus,
      setGetPatientError,
      buildStudyImage,
      checkFileStatus,
      share,
    };
  });

export default PatientStore;
