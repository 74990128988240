import { homeurl } from './baseurl';
import makeRequest from './makeRequest';

export const AuthApi = {
  login: async (login: string, password: string) => {
    const { data } = await makeRequest<{ token: string; expires: string }>({
      method: 'post',
      url: homeurl + '/auth',
      data: {
        login,
        password,
      },
    });
    return data;
  },
};
