import { baseurl } from './baseurl';
import { IPatientStudy } from '../store/patient';
import makeRequest from './makeRequest';

export const PatientApi = {
  getPatients: async () => {
    const { data } = await makeRequest<IPatientStudy[]>({
      url: baseurl + '/studies',
    });
    return data;
  },

  getStudyById: async (studyUUID: string | number, fileId: string | number) => {
    const { data } = await makeRequest<IPatientStudy>({
      url: baseurl + `/study/${studyUUID}/files/${fileId}`,
    });
    return data;
  },
};
