export const extractQueryParam = (url: string, param: string) => {
  return new URLSearchParams(url).get(param)
}

export const downloadFile = (fileUrl: string, fileName: string) => {
  const elem = window.document.createElement('a');
  elem.href = fileUrl;
  elem.download = fileName;
  elem.click();
}
