import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { DCInfo, Nav, Studies } from '../../components';
import useStore from '../../hooks/useStore';

const Patient: FC = () => {
  const { patientStore } = useStore();

  useEffect(() => {
    (async () => {
      await patientStore.getPatient();
      await Promise.all(
        patientStore.patients.map((p) => patientStore.getShares(p.patientUUID))
      );
    })();
  }, []);

  return (
    <>
      <DCInfo />
      <Nav />
      <Studies />
    </>
  );
};

export default observer(Patient);
