const PORT = process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : '';
const IBASE = process.env.REACT_APP_IBASE ? `/${process.env.REACT_APP_IBASE}` : '';
const HS = process.env.REACT_APP_HS ? `/${process.env.REACT_APP_HS}` : '';
const API_NAME = process.env.REACT_APP_API_NAME ? `/${process.env.REACT_APP_API_NAME}` : '';

export const baseurl =
  `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_IP}${PORT}${IBASE}${HS}${API_NAME}`;

export const homeurl =
  `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_IP}${PORT}${IBASE}${HS}${API_NAME}`;
