import { baseurl, homeurl } from './baseurl';
import makeRequest from './makeRequest';
import { IPatientStudy } from '../store/patient';

export interface ShareParams {
  objectUUID: string;
  objectType: 'patient' | 'study';
  expires: 7 | 30 | 90 | number;
  operation: 'create' | 'deny';
}

export const SharesApi = {
  shares: async (patientUUID: string) => {
    const { data } = await makeRequest({
      url: homeurl + '/shares',
      params: { patientUUID },
    });
    return data;
  },

  share: async (params: ShareParams) => {
    const { data } = await makeRequest({
      method: 'post',
      url: homeurl + '/shares',
      params,
    });
    return data;
  },

  getSharedStudies: async () => {
    const { data } = await makeRequest<IPatientStudy[]>({
      url: baseurl + '/sharedStudies'
    });
    return data;
  },
};
