import { types } from 'mobx-state-tree';

import PatientStore from './patient';
import FileStore from './openedFile';
import CashFiles from './cashFiles';

const RootStore = types.model('RootStore', {
  patientStore: types.optional(PatientStore, {}),
  cashFiles: types.optional(CashFiles, {}),
  fileStore: types.optional(FileStore, {}),
});

export default RootStore;
