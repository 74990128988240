import { FC } from 'react';

import { Button } from 'antd';

import styles from '../Patient.module.scss';

interface FileActionButtonsProps {
  onFileDownload: () => void;
  downloadFileLoading: boolean;
  onFileShow?: () => void;
}

const FileActionButtons: FC<FileActionButtonsProps> = ({
  onFileDownload,
  onFileShow,
  downloadFileLoading,
}) => {
  return (
    <div className={styles.actionButtons}>
      <Button
        loading={downloadFileLoading}
        size={'small'}
        style={{ marginRight: 10 }}
        onClick={() => onFileDownload()}
      >
        Завантажити
      </Button>
      {onFileShow && (
        <Button
          className={styles.openButton}
          size={'small'}
          onClick={() => onFileShow()}
        >
          Переглянути
        </Button>
      )}
    </div>
  );
};

export default FileActionButtons;
