import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Alert, Button, Form, Input } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useForm } from 'antd/es/form/Form';

import styles from './LoginPage.module.scss';

import { AuthApi } from '../../api/auth';
import { clearAuthToken, saveAuthToken } from '../../utils/tokenHelpers';
import { extractQueryParam } from '../../utils/commonHelpers';
import {DCInfo} from "../../components";

const LoginPage: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const login = new URLSearchParams(location.search).get('login');
    const password = new URLSearchParams(location.search).get('password');

    if (login && password) {
      form.validateFields().then(() => {
        form.submit();
      });
    }
  }, [location, form]);

  const onFinish = (values: any) => {
    (async () => {
      try {
        setLoading(true);
        const { token, expires } = await AuthApi.login(
          values.login,
          values.password
        );
        saveAuthToken(token, expires);
        setLoading(false);
        history.push('/');
      } catch (e: any) {
        setLoading(false);
        clearAuthToken();
        setError(e.message);
      }
    })();
  };

  return (
    <Form
      form={form}
      name="login"
      className={styles.form}
      initialValues={{
        login: extractQueryParam(location.search, 'login'),
        password: atob(extractQueryParam(location.search, 'password') || ''),
      }}
      onFinish={onFinish}
    >
      <DCInfo centered />
      <Title level={4} className={styles.title}>
        Авторизація
      </Title>
      <Form.Item
        label="Телефон"
        name="login"
        rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
      >
        <Input placeholder="380987654321" />
      </Form.Item>

      <Form.Item
        label="Пароль"
        name="password"
        rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
      >
        <Input.Password />
      </Form.Item>

      {!!error && (
        <Alert className={styles.errorMessage} message={error} type="error" />
      )}

      <Form.Item>
        <Button
          disabled={loading}
          loading={loading}
          className={styles.submit}
          type="primary"
          htmlType="submit"
        >
          Вхід
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginPage;
